import React from 'react';
import { Helmet } from 'react-helmet';
// import { ThemeProvider } from 'styled-components'

import './global.scss';
import './tailwind.css';
import AppContextWrapper from '@context/AppContext';
// import { ThemeProvider } from '@ui-library';
// import { defaultTheme } from '@ui-library';

export const wrapPageElement = ({ element }) => (
  <>
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Helmet>
    <AppContextWrapper>{element}</AppContextWrapper>
  </>
);
