import React, { createContext } from 'react';

const defaultState = {
    darkView: false,
    kittenMode: false,
};
export const AppContext = createContext(defaultState);

export class AppContextWrapper extends React.Component {
    state = defaultState;

    componentDidMount = () => {
        document.addEventListener('keydown', (event) => {
            // console.log('keydown event', event.key)
            if (event.key === '|') {
                this.activateKittenMode();
            }
        });
    };

    activateKittenMode = () => {
        console.log("it' meow time");
    };

    render() {
        const { children } = this.props;
        const { darkView, kittenMode } = this.state;
        return (
            <AppContext.Provider value={{ darkView, kittenMode }}>
                {children}
            </AppContext.Provider>
        );
    }
}

export default AppContextWrapper;
